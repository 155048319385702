import Navbar               from './neuro/Navbar'
import Feedback             from './neuro/Feedback'
import CharacterCounter     from './neuro/CharacterCounter'
import LanguageDropdown     from './neuro/LanguageDropdown'
import LanguageSwitchButton from './neuro/LanguageSwitchButton'
import SourceA              from './neuro/SourceA' # ContentEditable
import SourceB              from './neuro/SourceB' # Transparent textarea
import Target               from './neuro/Target'
import ToneSelector         from './neuro/ToneSelector'
import Marketing            from './neuro/Marketing'
import CallToAction         from './neuro/CallToAction'
import Footer               from './neuro/Footer'
import Modal                from './neuro/Modal'
import FilesPanel           from './neuro/panels/FilesPanel'
import AppsPanel            from './neuro/panels/AppsPanel'

import Segmenter           from './neuro/helpers/Segmenter'
import StreamingManagement from './neuro/helpers/StreamingManagement'

export default class Neuro extends React.PureComponent
  REGULAR_DEBOUNCE_TIME = 300  # in ms
  MOBILE_DEBOUNCE_TIME  = 1000

  MAX_DISPLAYED_SOURCE_SIZE  = 5000

  MAX_FAVORITE_LANGUAGES = 5
  DEFAULT_TARGET_CODE    = 'en-US' # only if nothing found in browser languages

  FONT_SIZE_CLASSES =
    biggest:  { min: 0,   max: 59                      }
    bigger:   { min: 60,  max: 179                     }
    normal:   { min: 180, max: 399                     }
    smaller:  { min: 400, max: 799                     }
    smallest: { min: 800, max: Number.MAX_SAFE_INTEGER }
  
  constructor: (props) ->
    super(props)

    @state =
      mode:                    @props.mode
      sources:                 [] #| Arrays of sentences
      targets:                 [] #/
      sourceCode:              ''
      targetCode:              DEFAULT_TARGET_CODE
      topSourceCodes:          []
      topTargetCodes:          []
      sourceDetection:         true
      tone:                    'auto'
      hiddenTones:             false
      alternatives:            []
      activeSentenceIndex:     -1 #| -1 if no active word/sentence
      activeWordIndex:         -1 #/
      wordAlternatives:        []
      engine:                  @props.defaultEngine
      limitWarningCode:        '' # if empty, warning is not shown
      modalOrigin:             ''
      waitingListLimit:        @props.waitingListLimit
      waitingListApi:          @props.waitingListApi
      waitingListFiles:        @props.waitingListFiles
      waitingListEngine:       @props.waitingListEngine
      loading:                 false
      wordAlternativesLoading: false

    @requestId  = '0.0'
    @responseId = '0.0'
    @alternativesResponseId = '0.0'

    @wordAlternativesRequestId = '0.0' # no need for response here, we only want to display last one

    # Generate hash to access language from code in O(1)
    @sourceLanguagePerCode = @buildLanguagePerCode(exceptCodes: ['en-US', 'en-GB'])
    @targetLanguagePerCode = @buildLanguagePerCode(exceptCodes: ['en'])

    # Used to put the focus back into the textarea after re-render
    @sourceRef        = React.createRef()
    @targetRef        = React.createRef()
    @targetContentRef = React.createRef()

    # Debounced method
    debounce_time = if @props.mobile then MOBILE_DEBOUNCE_TIME else REGULAR_DEBOUNCE_TIME
    @dTranslate   = _.debounce(@translate, debounce_time)

    # Bound methods
    @changeMode                = @changeMode.bind(this)
    @openModal                 = @openModal.bind(this)
    @updateWaitingListFlag     = @updateWaitingListFlag.bind(this)
    @updateSourcesAndTranslate = @updateSourcesAndTranslate.bind(this)
    @selectSourceCode          = @selectSourceCode.bind(this)
    @selectTargetCode          = @selectTargetCode.bind(this)
    @clearSource               = @clearSource.bind(this)
    @switchLanguages           = @switchLanguages.bind(this)
    @selectAlternative         = @selectAlternative.bind(this)
    @selectTone                = @selectTone.bind(this)
    @hideTones                 = @hideTones.bind(this)
    @showTones                 = @showTones.bind(this)
    @selectEngine              = @selectEngine.bind(this)
    @focusSource               = @focusSource.bind(this)
    @setActiveSentence         = @setActiveSentence.bind(this)
    @setActiveWord             = @setActiveWord.bind(this)
    @selectWordAlternative     = @selectWordAlternative.bind(this)
    @selectTargetText          = @selectTargetText.bind(this)
    @unselectTargetText        = @unselectTargetText.bind(this)

  componentDidMount: ->
    # "didMount" because localStorage is not available when prerendering
    @loadTopLanguageCodes( =>
      @setState(
        targetCode: @state.topTargetCodes[0]
      )
    )

    @loadEngine()
    @bindWebsockets()
    @manageModalOnLoading()
    @bindShortcuts()

  buildLanguagePerCode: (options = { exceptCodes: [] }) ->
    # Build hash
    languagePerCode = {}
    @props.languages.forEach((language) => languagePerCode[language.code] = language)

    # Remove extra codes
    options.exceptCodes.forEach((code) => delete languagePerCode[code])

    languagePerCode

  loadTopLanguageCodes: (callback) ->
    # Top languages from localStorage
    topSourceCodes = localStorage.getItem('top-source-codes')?.split(',') || []
    topTargetCodes = localStorage.getItem('top-target-codes')?.split(',') || []

    # Top languages from browser
    browserLanguageCodes = navigator.languages || []
    browserLanguageCodes = browserLanguageCodes.map((language) => [language, language.split('-')[0]]).flat() # Be sure to add 'fr' after 'fr-FR' in navigator.languages when it's not there (it happens, and we saw ['fr-FR', 'be-AE', 'be', 'fr-FR', 'fr'] in the wild)
    browserLanguageCodes = _.uniq(browserLanguageCodes)

    # Merge them with priority of localStorage
    topSourceCodes = _.uniq(topSourceCodes.concat(browserLanguageCodes))
    topTargetCodes = _.uniq(topTargetCodes.concat(browserLanguageCodes))

    # Remove non-accepted languages
    topSourceCodes = topSourceCodes.filter((code) => @sourceLanguagePerCode[code])
    topTargetCodes = topTargetCodes.filter((code) => @targetLanguagePerCode[code])

    # Keep max languages
    topSourceCodes = topSourceCodes.slice(0, MAX_FAVORITE_LANGUAGES)
    topTargetCodes = topTargetCodes.slice(0, MAX_FAVORITE_LANGUAGES)

    # Set default if no target codes
    topTargetCodes = [DEFAULT_TARGET_CODE] if topTargetCodes.length == 0

    @setState(
      topSourceCodes: topSourceCodes
      topTargetCodes: topTargetCodes
    , callback)

  loadEngine: ->
    engine = localStorage.getItem('engine')

    if engine && engine != @state.engine && @props.authorizedEngines.includes(engine)
      @setState(engine: engine)

  saveTopLanguageCodes: ->
    localStorage.setItem('top-source-codes', @state.topSourceCodes.join(','))
    localStorage.setItem('top-target-codes', @state.topTargetCodes.join(','))

  addTopCode: (code, type) ->
    if code.length
      topCodeType = "top#{_.capitalize(type)}Codes"

      topCodes = [code].concat(@state[topCodeType])        # Add new language in first position
      topCodes = _.uniq(topCodes)                          # Remove duplicates
      topCodes = topCodes.slice(0, MAX_FAVORITE_LANGUAGES) # Keep only the first x (see MAX)

      @setState(
        "#{topCodeType}": topCodes
      , @saveTopLanguageCodes)

  addTopSourceCode: (code) ->
    @addTopCode(code, 'source')

  addTopTargetCode: (code) ->
    @addTopCode(code, 'target')

  focusSource: ->
    if @state.mode == 'text'
      $('.col-source .textarea')[0].focus()

  sentencesLength: (sentences) ->
    _.sumBy(sentences, (sentence) -> sentence.length)

  sourceLength: ->
    @sentencesLength(@state.sources)

  targetLength: ->
    @sentencesLength(@state.targets)

  # Truncate to last char
  truncateForDisplay: (sentences) ->
    maxSize         = MAX_DISPLAYED_SOURCE_SIZE
    sentencesLength = @sentencesLength(sentences)

    if maxSize > sentencesLength
      return sentences
    else
      newSentences = []
      sourceSize   = 0

      for sentence in sentences
        if maxSize > sourceSize + sentence.length
          newSentences.push(sentence)
          sourceSize += sentence.length
        else # crop the last sentence
          newSentences.push(
            sentence.substring(0, maxSize - sourceSize) # get the maximum number of chars from this last sentence
          )
          break

      return newSentences

  # Truncate to last sentence
  truncateForTranslation: (sentences) ->
    maxSize         = @props.limits.char
    sentencesLength = @sentencesLength(sentences)

    if maxSize > sentencesLength
      return sentences
    else
      newSentences = []
      sourceSize   = 0

      for sentence in sentences
        newSentences.push(sentence)
        sourceSize += sentence.length

        # We do not want to crop mid-sentence, so we break once the limit is reached
        break if sourceSize >= maxSize

      return newSentences

  changeMode: (mode) ->
    @setState(mode: mode, =>
      @clearSource()
      history.pushState({}, '', "/#{@props.currentLocale}/#{@state.mode}")
      @updateMetaTags()
    )

  updateMetaTags: ->
    $('title').text(@props.i18n.page[@state.mode].title)
    $('meta[name=description]').attr('content', @props.i18n.page[@state.mode].description)
    $('meta[name=keywords]').attr('content', @props.i18n.page[@state.mode].keywords)

  openModal: (origin, e) ->
    e.preventDefault() if e

    @setState({
      modalOrigin: origin
    }, =>
      modal = Bootstrap.Modal.getOrCreateInstance('#modal-neuro')
      modal.show()
    )

  updateWaitingListFlag: (flag) ->
    switch flag
      when 'limit'  then @setState(waitingListLimit: true)
      when 'api'    then @setState(waitingListApi: true)
      when 'files'  then @setState(waitingListFiles: true)
      when 'engine' then @setState(waitingListEngine: true)

  updateSourcesAndTranslate: (sentences) ->
    # be sure to close word_alternative menu
    @setActiveWord(-1)

    if sentences.length
      @setState({
        sources: @truncateForDisplay(sentences),
      }, =>
        @dTranslate()
      )
    else
      @clearSource()

  clearSource: (e) ->
    # If source detection, revert to pure 'Detect Language' without suggestion
    newSourceCode = if @state.sourceDetection then '' else @state.sourceCode

    @setState(
      sources:          []
      targets:          []
      sourceCode:       newSourceCode
      alternatives:     []
      limitWarningCode: ''
    , =>
      @translate() # we now it won't translate empty string, but it will deal with requestId/responseId and avoid bugs
      @focusSource()
    )

  # Special cases where source code doesn't exist as target code, we need to use best equivalent
  sourceCodeToTargetCode: (sourceCode) ->
    if sourceCode == 'en'
      @state.topTargetCodes.concat(['en-US']).find((targetCode) => ['en-US', 'en-GB'].includes(targetCode))
    else
      sourceCode

  # Special cases where target code doesn't exist as source code, we need to use best equivalent
  targetCodeToSourceCode: (targetCode) ->
    if ['en-US', 'en-GB'].includes(targetCode)
      'en'
    else
      targetCode

  switchLanguages: (e) ->
    if @hasSourceLanguage()
      @setState(
        sources:         @state.targets
        targets:         @state.sources
        sourceCode:      @targetCodeToSourceCode(@state.targetCode)
        targetCode:      @sourceCodeToTargetCode(@state.sourceCode)
        sourceDetection: false
        alternatives:    []
      , @translate)

  sourceLanguageDirection: ->
    @languageDirection(@sourceLanguagePerCode[@state.sourceCode])

  targetLanguageDirection: ->
    @languageDirection(@targetLanguagePerCode[@state.targetCode])

  languageDirection: (language) ->
    # No language is passed when the source language is still on "auto-detect",
    # so we let the browser decide automatically from the first characters
    # (it's faster than waiting for the language-detection response)
    if !language
      'auto'
    else if language.rightToLeft
      'rtl'
    else
      'ltr'

  selectAlternative: (targets) ->
    # Remove selected alternative from list
    newAlternatives = @state.alternatives.filter((alternativeTargets) =>
      !_.isEqual(alternativeTargets, targets)
    )

    # Add current targets in alternatives
    newAlternatives.push(@state.targets)

    @setState(
      targets:      targets
      alternatives: newAlternatives
    , @selectTargetText)

  selectTone: (tone) ->
    @setState(
      tone: tone
    , @translate)

  hideTones: ->
    @setState(hiddenTones: true)

  showTones: ->
    @setState(hiddenTones: false)

  selectEngine: (engine) ->
    if engine != @state.engine
      localStorage.setItem('engine', engine) # save last selected engine!

      @setState(
        engine: engine
      , @translate)

  # We don't necessarily want to apply the response from the backend.
  # if the source is too short and the language too random.
  detectSourceCode: (responseSourceCode) ->
    sourceLength = @sourceLength()

    if sourceLength >= 3 && @state.topSourceCodes.includes(responseSourceCode)
      responseSourceCode # We are quite confident this is the language we want (3 chars are enough)
    else if sourceLength >= 7
      responseSourceCode # This language is weird, but 7 chars are enough to convince us that's the one we want
    else
      @state.sourceCode  # If we are not sure of the new language, we keep the old one!

  sameSourceAndTargetCodes: (sourceCode, targetCode) ->
    sourceCode ||= @state.sourceCode #| If no parameters passed, use sourceCode
    targetCode ||= @state.targetCode #/ and targetCode from state

    same              = targetCode == sourceCode
    sameEnglishPrefix = sourceCode == 'en' && sourceCode == targetCode.split('-')[0]

    same || sameEnglishPrefix

  ensureSourceCodeConsistency: (previousTargetCode) ->
    # Revert source language to "detection" if same on both sides
    if @sameSourceAndTargetCodes()
      @setState(
        sourceCode:      ''
        sourceDetection: true
      , @translate)

  ensureTargetCodeConsistency: (previousSourceCode) ->
    if @sameSourceAndTargetCodes()
      if previousSourceCode == '' # Use another preferred language for target if source was in detection
        targetCode = @state.topTargetCodes.concat([DEFAULT_TARGET_CODE, 'fr']) # fallback to 'en-US' and 'fr' (first one != sourceCode) if no other top languages available
                                          .find((targetCode) => !@sameSourceAndTargetCodes(@state.sourceCode, targetCode))
      else
        targetCode = @sourceCodeToTargetCode(previousSourceCode) # if source language was not auto-detected, simply use source code

      @setState(
        targetCode: targetCode
      , @translate)

  selectSourceCode: (code) ->
    previousSourceCode = @state.sourceCode

    @setState(
      sourceCode:      code
      sourceDetection: code == '' # Enable language selection only if "Detect Language" is selected
    , =>
      @addTopSourceCode(code)
      @translate()
      @ensureTargetCodeConsistency(previousSourceCode) # will retrigger translate if needed
    )

  selectTargetCode: (code) ->
    previousTargetCode = @state.targetCode

    @setState(
      targetCode: code
    , =>
      @addTopTargetCode(code)
      @translate()
      @ensureSourceCodeConsistency(previousTargetCode) # will retrigger translate if needed
    )

  setActiveSentence: (index, callback) ->
    @setState(activeSentenceIndex: index, callback)

  setActiveWord: (index, word) ->
    if index == -1 # unselect active word
      @setState(
        activeWordIndex:  -1
        wordAlternatives: []
      )
    else
      source = @state.sources[@state.activeSentenceIndex].trim()
      target = @state.targets[@state.activeSentenceIndex].trim()

      # Replace selected word in target as [[word]] for backend service
      targetWords = Segmenter.words(target, @state.targetCode)
      originalWord = targetWords[index]
      targetWords[index] = "[[#{word}]]"
      target = targetWords.join('')

      @wordAlternativesRequestId = @incrementVersion(@wordAlternativesRequestId)

      params =
        source:             source
        target:             target
        originalWord:       originalWord
        sourceLanguageCode: @state.sourceCode # TODO: should we ignore cases when sourceCode == '' ? (not detected... yet)
        targetLanguageCode: @state.targetCode
        engine:             @state.engine
        sessionUid:         @props.sessionUid
        requestId:          @wordAlternativesRequestId

      @setState(
        activeWordIndex:         index
        wordAlternativesLoading: true
      , =>
        http.post("/word_alternatives.json", params, (data) =>
          if !data.enqueued # Response will come asynchronously with websockets, we can ignore the response now.
            console.log('TRANSLATION ACTION WAS NOT CORRECTLY ENQUEUED')
        )
      )

  selectWordAlternative: (wordAlternative) ->
    # 1. Only keep sentences before selected one as preTargets
    preTargets = @state.targets.slice(0, @state.activeSentenceIndex)

    # 2. Replace word in current target sentence using [[]]
    currentTarget      = @state.targets[@state.activeSentenceIndex]
    currentTargetWords = Segmenter.words(currentTarget, @state.targetCode)
    originalWord       = currentTargetWords[@state.activeWordIndex]
    currentTargetWords[@state.activeWordIndex] = "[[#{originalWord}]]"

    # 3. Remove end of last sentence
    currentTargetWords = currentTargetWords.slice(0, @state.activeWordIndex + 1)
    currentTarget      = currentTargetWords.join('')

    @setState(targets: preTargets.concat([currentTargetWords.slice(0, @state.activeWordIndex).join('')]))

    @requestId = @incrementVersion(@requestId)
    @startLoading()

    params =
      sources:            @truncateForTranslation(@state.sources)
      preTargets:         preTargets        # What's before the selected sentence
      currentTarget:      currentTarget     # The current sentence ending with "[[originalWord]]"
      wordAlternative:    wordAlternative   # The selected word alternative to continue the sentence
      sourceLanguageCode: @state.sourceCode # TODO: should we ignore cases when sourceCode == '' ? (not detected... yet)
      targetLanguageCode: @state.targetCode
      engine:             @state.engine
      sessionUid:         @props.sessionUid
      requestId:          @requestId

    @setState(
      activeWordIndex:  -1,
      wordAlternatives: []
    , =>
      http.post("/apply_word_alternative.json", params, (data) =>
        if !data.enqueued # Response will come asynchronously with websockets, we can ignore the response now.
          console.log('TRANSLATION ACTION WAS NOT CORRECTLY ENQUEUED')
      )
    )

  emptySource: ->
    @state.sources.length == 0 || @state.sources.every((sentence) -> sentence.trim().length == 0)

  sameLanguages: ->
    @state.sourceCode == @state.targetCode && !@state.sourceDetection

  hasSourceLanguage: ->
    @state.sourceCode != ''

  canSwitchLanguages: ->
    @hasSourceLanguage() && !@sameLanguages()

  translate: ->
    @requestId = @incrementVersion(@requestId)

    if @emptySource()
      @responseId             = @requestId
      @alternativesResponseId = @requestId

      @stopLoading()

      @setState(
        targets:      []
        alternatives: []
      )
    else if @sameLanguages()
      @responseId             = @requestId
      @alternativesResponseId = @requestId

      @stopLoading()

      @setState(
        targets:      @state.sources
        alternatives: []
      )
    else
      @startLoading()

      params =
        sources:            @truncateForTranslation(@state.sources)
        sourceLanguageCode: if @state.sourceDetection then '' else @state.sourceCode
        targetLanguageCode: @state.targetCode
        tone:               @state.tone
        engine:             @state.engine
        sessionUid:         @props.sessionUid
        requestId:          @requestId

      http.post("/translate.json", params, (data) =>
        if !data.enqueued # Response will come asynchronously with websockets, we can ignore the response now.
          console.log('TRANSLATION ACTION WAS NOT CORRECTLY ENQUEUED')
      , @handleAjaxError.bind(this))

  handleAjaxError: (error) ->
    if error.responseJSON['error_type'] == 'limit_exceeded'
      @setState(
        limitWarningCode: error.responseJSON['error_code']
      , @stopLoading)

  bindWebsockets: ->
    testEnvSuffix = if @props.environment == 'test' then "-test#{@props.testEnvNumber}" else ''
    channel       = Pusher.instance.subscribe("public-neuro-#{@props.sessionUid}#{testEnvSuffix}")

    # Receive new translation
    channel.bind('translate', (data) =>
      data = humps.camelizeKeys(data)
      @applyTranslateResponse(data)
    )

    # Receive new alternatives
    channel.bind('alternatives', (data) =>
      data = humps.camelizeKeys(data)
      @applyAlternativesResponse(data)
    )

    # Receive new word alternatives
    channel.bind('word_alternatives', (data) =>
      data = humps.camelizeKeys(data)
      @applyWordAlternativesResponse(data)
    )

  applyTranslateResponse: (data) ->
    if data.requestId && @isBiggerVersion(data.requestId, @responseId)
      @responseId = data.requestId

      streamingManagement = new StreamingManagement(
        { # current state
          id:      @requestId,
          sources: @state.sources
          targets: @state.targets
        },
        { # latest request
          id:      data.requestId,
          sources: data.sources
          targets: data.targets
        },
        data.completion # 'partial' / 'sentence' / 'complete''
      )

      newTargets = streamingManagement.bestTargets()

      previousSourceCode = @state.sourceCode

      if @isLastRequest()
        @stopLoading()

      # Remove alternatives if they don't match the latest requestId anymore
      if @isBiggerVersion(@requestId, @alternativesResponseId)
        @setState(alternatives: [])

      # Check if the character limit in the source was reached
      if @sourceLength() >= @props.limits.char && @isLastRequest() && data.completion == 'complete'
        limitWarningCode = 'char'
      else
        limitWarningCode = ''

      @setState(
        targets:          newTargets
        sourceCode:       @detectSourceCode(data.sourceLanguageCode)
        limitWarningCode: limitWarningCode
      , =>
        @ensureTargetCodeConsistency(previousSourceCode)
      )

  applyAlternativesResponse: (data) ->
    if data.requestId && @isBiggerVersion(data.requestId, @alternativesResponseId)
      @alternativesResponseId = data.requestId

      # Only keep alternatives that are not the current targets
      # => May be open to race conditions but alternatives should be slower than translation.
      # => Even if it doesn't work, it's also filtered out at the `render` (could glitch when fading-out but it's not so bad)
      alternatives = data.alternatives.filter((alternativeTargets) =>
        !_.isEqual(@state.targets, alternativeTargets)
      )

      @setState(
        alternatives: alternatives
      )

  applyWordAlternativesResponse: (data) ->
    if data.requestId && data.requestId == @wordAlternativesRequestId
      @setState(
        wordAlternatives:        if data.wordAlternatives.length then data.wordAlternatives else [data.originalWord]
        wordAlternativesLoading: false
      )

  manageModalOnLoading: ->
    @openModal(@props.modalToOpen) if @props.modalToOpen

  bindShortcuts: ->
    ctrlModifier = if navigator.platform.includes('Mac') then 'meta' else 'ctrl'

    # Can be used anywhere on the page except in actual input fields
    $(document).on('keydown', null, "#{ctrlModifier}+a", @selectTargetText)

  selectTargetText: (e) ->
    e.preventDefault() if e

    targetArea    = @targetRef.current
    targetContent = @targetContentRef.current

    targetArea.focus()

    range = document.createRange()
    range.setStart(targetContent, 0)
    range.setEnd(targetContent, targetContent.childNodes.length)

    selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)

  unselectTargetText: (e) ->
    selection = window.getSelection()
    selection.removeAllRanges()

  fontSizeClass: ->
    longestLength = Math.max(@sourceLength(), @targetLength())

    Object.entries(FONT_SIZE_CLASSES).find(([k, bounds]) =>
      bounds.min <= longestLength <= bounds.max
    )[0]

  isBiggerVersion: (version1, version2) ->
    splitted1 = version1.split('.')
    splitted2 = version2.split('.')

    firstNumber1 = parseInt(splitted1[0])
    firstNumber2 = parseInt(splitted2[0])
    lastNumber1  = parseInt(splitted1[1])
    lastNumber2  = parseInt(splitted2[1])

    firstNumber1 > firstNumber2 || (firstNumber1 == firstNumber2 && lastNumber1 > lastNumber2)

  # Last request but not necessarily last websocket
  isLastRequest: ->
    !@isBiggerVersion(@requestId, @responseId)

  # increment first number only (second number is for websockets)
  incrementVersion: (version) ->
    newVersion = parseInt(version.split('.')[0]) + 1
    "#{newVersion}.0"

  startLoading: ->
    @setState(loading: true)
    @startLoadingTitleAnimation()

  stopLoading: ->
    @setState(loading: false)
    @stopLoadingTitleAnimation()

  startLoadingTitleAnimation: ->
    @titleAnimation ||= setInterval( =>
      ooos = ['ooo', 'Ooo', 'oOo', 'ooO']

      if document.title.includes(ooos[0])
        document.title = document.title.replace(ooos[0], ooos[1])
      else if document.title.includes(ooos[1])
        document.title = document.title.replace(ooos[1], ooos[2])
      else if document.title.includes(ooos[2])
        document.title = document.title.replace(ooos[2], ooos[3])
      else if document.title.includes(ooos[3])
        document.title = document.title.replace(ooos[3], ooos[0])
    , 250) # faster than that and Chrome won't refresh quickly enough (the number of refrehes seems limited)

  stopLoadingTitleAnimation: ->
    clearInterval(@titleAnimation)
    @titleAnimation = null

    document.title = document.title.replace('Ooo', 'ooo') # go back to original capitalization
                                   .replace('oOo', 'ooo')
                                   .replace('ooO', 'ooo')

  render: ->
    <>
      { @renderHeader() }
      { @renderMain()   }
      { @renderFooter() }
      { @renderModal()  }
    </>

  renderHeader: ->
    <Navbar mode={@state.mode}
            currentUser={@props.currentUser}
            currentLocale={@props.currentLocale}
            i18n={@props.i18n.navbar}
            paths={@props.paths}
            blogNotification={@props.blogNotification}
            changeMode={@changeMode}
            openModal={@openModal} />

  renderMain: ->
    <main>
      { @renderNeuroInterface() }
      { @renderMarketing()      if !@props.currentUser }
      { @renderCallToAction()   if !@props.currentUser }
    </main>

  renderNeuroInterface: ->
    classes = "container-lg neuro-container #{@state.mode}-mode"

    <div className={classes}>
      <div className="neuro card">
        { @renderToolbar() }
        { @renderPanel() }
      </div>

      <Feedback i18n={@props.i18n.feedback} />
    </div>

  renderToolbar: ->
    if @state.mode in ['text', 'files']
      <div className="row row-cols-2 row-toolbar" key="toolbar">
        <div className="col col-toolbar col-toolbar-source">
          <LanguageDropdown placeholder={@props.i18n.languageDropdown.sourcePlaceholder}
                            languageCode={@state.sourceCode}
                            topCodes={@state.topSourceCodes}
                            maxCodes={MAX_FAVORITE_LANGUAGES}
                            languagePerCode={@sourceLanguagePerCode}
                            autoDetectOption={true}
                            detection={@state.sourceDetection}
                            onSelectLanguage={@selectSourceCode.bind(this)}
                            focusSource={@focusSource}
                            i18n={@props.i18n.languageDropdown} />
        </div>
        <div className="col col-toolbar col-toolbar-target">
          <LanguageDropdown placeholder={@props.i18n.languageDropdown.targetPlaceholder}
                            languageCode={@state.targetCode}
                            topCodes={@state.topTargetCodes}
                            maxCodes={MAX_FAVORITE_LANGUAGES}
                            languagePerCode={@targetLanguagePerCode}
                            autoDetectOption={false}
                            detection={false}
                            onOpen={@hideTones}
                            onClose={@showTones}
                            onSelectLanguage={@selectTargetCode.bind(this)}
                            focusSource={@focusSource}
                            i18n={@props.i18n.languageDropdown} />
          { @renderToneSelector() }
        </div>
        <LanguageSwitchButton disabled={!@canSwitchLanguages()}
                              onClick={@switchLanguages} />
      </div>

  renderToneSelector: ->
    if @state.mode == 'text'
      <ToneSelector tone={@state.tone}
                    tones={@props.tones}
                    hidden={@state.hiddenTones}
                    onChange={@selectTone}
                    i18n={@props.i18n.toneSelector} />

  renderPanel: ->
    switch @state.mode
      when 'text'  then @renderTranslationPanel()
      when 'files' then @renderFilesPanel()
      when 'apps'  then @renderAppsPanel()

  renderTranslationPanel: ->
    classes = "row row-cols-1 row-cols-md-2 row-panel"
    classes += " empty-source" if @emptySource() # This allows us to hide the target panel and the separator line on mobile view

    <div className={classes} key="translation">
      <div className="col col-source">
        {@renderSource()}
        {@renderClearSourceButton()}
        <CharacterCounter textSize={@sourceLength()}
                          maxTextSize={@props.limits.char} />
      </div>
      <div className="col col-target">
        {@renderTarget()}
      </div>
    </div>

  renderFilesPanel: ->
    classes = "row row-cols-1 row-panel"

    <div className={classes} key="files">
      <FilesPanel openModal={@openModal}
                  i18n={@props.i18n.filesPanel} />
    </div>

  renderAppsPanel: ->
    classes = "row row-cols-1 row-panel"

    <div className={classes} key="apps">
      <AppsPanel assets={@props.assets}
                 i18n={@props.i18n.appsPanel} />
    </div>

  renderClearSourceButton: ->
    if @sourceLength()
      <button className="btn-close btn-clear-source"
              onClick={@clearSource}
              tabIndex="-1">
      </button>

  renderSource: ->
    if @props.abTesting == 'a'
      <SourceA sourceRef={@sourceRef}
               sources={@state.sources}
               languageCode={@state.sourceCode}
               direction={@sourceLanguageDirection()}
               fontSizeClass={@fontSizeClass()}
               activeSentenceIndex={@state.activeSentenceIndex}
               onChange={@updateSourcesAndTranslate}
               i18n={@props.i18n.source} />
    else
      <SourceB sourceRef={@sourceRef}
               sources={@state.sources}
               languageCode={@state.sourceCode}
               direction={@sourceLanguageDirection()}
               fontSizeClass={@fontSizeClass()}
               activeSentenceIndex={@state.activeSentenceIndex}
               onChange={@updateSourcesAndTranslate}
               i18n={@props.i18n.source} />

  renderTarget: ->
    <Target targetRef={@targetRef}
            targetContentRef={@targetContentRef}
            targets={@state.targets}
            languageCode={@state.targetCode}
            direction={@targetLanguageDirection()}
            loading={@state.loading}
            alternatives={@state.alternatives}
            wordAlternatives={@state.wordAlternatives}
            wordAlternativesLoading={@state.wordAlternativesLoading}
            fontSizeClass={@fontSizeClass()}
            selectAlternative={@selectAlternative}
            activeSentenceIndex={@state.activeSentenceIndex}
            activeWordIndex={@state.activeWordIndex}
            setActiveSentence={@setActiveSentence}
            setActiveWord={@setActiveWord}
            selectWordAlternative={@selectWordAlternative}
            selectTargetText={@selectTargetText}
            unselectTargetText={@unselectTargetText}
            engine={@state.engine}
            engines={@props.engines}
            authorizedEngines={@props.authorizedEngines}
            selectEngine={@selectEngine}
            limitWarningCode={@state.limitWarningCode}
            maxSourceSize={@props.limits.char}
            openModal={@openModal}
            currentUser={@props.currentUser}
            paths={@props.paths}
            i18n={@props.i18n.target} />

  renderMarketing: ->
    <Marketing assets={@props.assets}
               mode={@state.mode}
               i18n={@props.i18n.marketing} />

  renderCallToAction: ->
    # Merge the relevant i18n keys
    mergedI18n = {
      ...@props.i18n.callToAction                  # needed by the "CallToAction" component
      waitingListForm: @props.i18n.waitingListForm # needed by the shared "WaitingListForm" component
    }

    <CallToAction updateWaitingListFlag={@updateWaitingListFlag}
                  currentLocale={@props.currentLocale}
                  i18n={mergedI18n} />

  renderFooter: ->
    <Footer locales={@props.locales}
            currentLocale={@props.currentLocale}
            i18n={@props.i18n.footer} />

  renderModal: ->
    # Merge the relevant i18n keys
    mergedI18n = {
      ...@props.i18n.modal                         # needed by the "Modal" component
      waitingListForm: @props.i18n.waitingListForm # needed by the shared "WaitingListForm" component
    }

    <Modal environment={@props.environment}
           origin={@state.modalOrigin}
           paths={@props.paths}
           currentUser={@props.currentUser}
           waitingListLimit={@state.waitingListLimit}
           waitingListApi={@state.waitingListApi}
           waitingListFiles={@state.waitingListFiles}
           waitingListEngine={@state.waitingListEngine}
           updateWaitingListFlag={@updateWaitingListFlag}
           currentLocale={@props.currentLocale}
           i18n={mergedI18n} />
